export class modal {
    constructor() {
        this.init();
    }

    init(): void {
        const modalToggles = document.querySelectorAll<HTMLButtonElement>('button[data-dialog-target]');

        modalToggles.forEach((toggle) => {
            const id = toggle.dataset.dialogTarget;
            const dialogElement = document.getElementById(id ?? '') as HTMLDialogElement;
            const closeButton = dialogElement.querySelector<HTMLButtonElement>('.dialog-close');

            if (!id) {
                return;
            }

            toggle.addEventListener('click', () => {
                dialogElement.showModal();
                addHash(id);
            });

            closeButton?.addEventListener('click', () => {
                dialogElement.close();
                removeHash();
            });
        });

        if (window.location.hash) {
            const hash = window.location.hash;
            const dialog = document.getElementById(hash.substring(1)) as HTMLDialogElement;
            if (dialog && dialog.classList.contains('dialog-person')) {
                dialog.showModal();
            }
        }

        const addHash = (hash: string) => {
            const currentUrl = window.location.href.split('#')[0];
            const newUrl = `${currentUrl}#${hash}`;
            history.replaceState(null, '', newUrl);
        };

        const removeHash = () => {
            const currentUrl = window.location.href.split('#')[0];
            history.replaceState(null, '', currentUrl);
        };
    }
}
